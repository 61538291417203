.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    width: 1100px;
    max-width: 100%;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 451px;
}

.email {
    color: var(--color-brand-2);
}

.tel {
    white-space: nowrap;
    color: var(--color-brand-1);
}

.image {
    width: 600px;
    max-width: 100%;
}



@media screen and (max-width: 1000px) {
    .wrapper {
        position: static;
        flex-direction: column;
        transform: translate(0);
        padding: 20px;
    }

    .textWrapper {
        gap: 24px;
        max-width: 100%;
    }
}



@media screen and (max-width: 480px) {
    .tels {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}
