.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px 20px 40px 20px;
    margin-left: 381px;
    height: 100%;
    overflow-y: auto;
    border-radius: 4px;
    background-color: var(--color-white);
    transition: margin-left .3s ease-in-out;
}
.wrapper::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 6px;
    border: 1px solid transparent;
}
.wrapper::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.wrapperFull {
    margin-left: 15px;
}

.section, .subsection, .element, .tablesWrapper, .tableWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.section, .subsection, .tablesWrapper {
    gap: 24px;
}

.element, .tableWrapper {
    gap: 16px;
}

.tableWrapper {
    gap: 8px;
}

.table {
    overflow-y: auto;
}

.buttonsNavigation {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
}

.buttonsNavigation button {
    min-width: 110px;
}



@media screen and (max-width: 480px) {
    .wrapper {
        margin-left: 0;
    }
}
