.header, .header-small, .subheader,
.body, .l, .m, .small, .s, .xs, .xxs {
    font-family: Inter, sans-serif;
    font-style: normal;
    padding: 0;
    margin: 0;
    transition: color .2s;
}

.header {
    font-weight: 500;
    font-size: 42px;
    line-height: 56px;
    letter-spacing: -1px;
}

.header-small {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
}

.subheader {
    font-weight: 500;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -1px;
}

.body {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.l {
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.m {
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.1px;
}

.small {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.s {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.xs {
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.xxs {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15px;
}

.xxxs {
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.medium {
    font-weight: 500;
}

.semiBold {
    font-weight: 600;
}

.bold {
    font-weight: bold;
}
