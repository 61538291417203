.line, .checkbox {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 8px;
    align-items: center;
}

.checkbox {
    gap: 8px;
}

.region {
    line-height: 150%;
}

.cities {
    margin-left: 45px;
}


.line {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 8px;
    align-items: center;
    margin-left: 44px;
}

.myElement::before {
    content: ''; /* Необходимо для создания псевдоэлемента */
    position: absolute; /* Позволяет позиционировать псевдоэлемент внутри .myElement */
    top: 0; /* Расположение псевдоэлемента */
    width: 100%; /* Ширина фона */
    height: 27px; /* Высота фона */
    background-color: var(--color-gray-2); /* Цвет фона */
}

.myElement {
    position: relative; /* Необходимо для корректного позиционирования псевдоэлемента */
    /* Другие стили для .myElement */
}
