.title {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.content {
    overflow: hidden;
}

.angleBracket {
    position: relative;
    top: -2px;
    width: 7px;
    height: 7px;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    transform: rotate(135deg);
}

.angleBracketVisible {
    top: 2px;
    transform: rotate(-45deg);
}
