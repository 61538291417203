.titles {
    padding-right: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: transparent;
}

.wrapper {
    height: calc(100% - 54px);
    padding: 8px 0;
    margin-right: 25px;
    background-color: var(--color-white);
    border-radius: 4px;
    overflow-y: auto;
}
.wrapper::-webkit-scrollbar {
     background-color: transparent;
     width: 5px;
     height: 6px;
     border: 1px solid transparent;
 }
.wrapper::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}

.closedTitles {
    background-color: var(--color-white);
}

.section, .subsection {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
}

.section {
    gap: 4px;
}

.section > div:first-child {
    padding: 4px 16px !important;
    margin-bottom: 4px;
}

.subsection {
    padding: 8px 32px;
}

.section:hover, .subsection:hover {
    cursor: pointer;
}

.section > div:first-child:hover .sectionText, .subsection:hover > span {
    color: var(--color-brand-2) !important;
}

.subsection:hover, .titles:not(.closedTitles) .active {
    border-right: 3px solid var(--color-brand-2);
    background-color: var(--color-gray-3);
}



@media screen and (max-width: 480px) {
    .titles {
        max-height: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .wrapper {
        height: 100%;
        padding-top: 10px;
        margin-right: 10px;
    }
}
