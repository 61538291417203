.wrapper {
    position: absolute;
    left: 30px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-right: 15px;
    min-width: 356px;
    max-width: 356px;
    height: 100%;
    max-height: 95%;
    background-color: var(--color-gray-3);
    transition: all .3s ease-in-out;
}

.wrapperHidden {
    left: -322px;
    padding-right: 35px;
}

.wrapperHidden .arrow {
    left: -2px;
    transform: rotate(225deg);
}

.arrowWrapper {
    position: absolute;
    top: 0;
    right: -15px;
    z-index: 100;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-5);
    transition: right .3s ease-in-out;
}

.arrow {
    position: relative;
    left: 2px;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid var(--color-gray-8);
    border-left: 1px solid var(--color-gray-8);
    transform: rotate(45deg);
    transition: all .3s ease-in-out;
}



@media screen and (max-width: 480px) {
    .wrapper {
        top: 0;
        left: 0;
        min-width: calc(100%);
        max-width: calc(100%);
        padding: 0 16px;
        margin-top: 12px;
        max-height: calc(100% - 12px);
    }

    .wrapperHidden {
        min-width: calc(100% - 50px);
        max-width: calc(100% - 50px);
        left: calc(-100% + 60px);
    }

    .wrapper .arrowWrapper {
        right: 10px;
    }

    .wrapperHidden .arrowWrapper {
        right: -20px;
    }
}
