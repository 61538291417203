@font-face {
    font-family: 'Neue-Haas-Unica-Regular';
    src: url('./NeueHaasUnica-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Neue-Haas-Unica-Heavy';
    src: url('./NeueHaasUnica-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Neue-Haas-Unica-Medium';
    src: url('./NeueHaasUnica-Medium.ttf') format('truetype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url('./Inter-Thin.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url('./Inter-ExtraLight.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url('./Inter-Light.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url('./Inter-Regular.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url('./Inter-Medium.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url('./Inter-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url('./Inter-Bold.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url('./Inter-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url('./Inter-Black.otf') format('opentype');
}

