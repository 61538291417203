.header, .leftHeader, .rightHeader {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-color: var(--color-blue);
}

.header {
    justify-content: space-between;
}

.leftHeader {
    gap: 6px;
}

.leftHeader > *:first-child {
    font-family: Neue-Haas-Unica-Heavy, sans-serif;
    font-weight: 800;
}

.leftHeader > *:last-child {
    font-family: Neue-Haas-Unica-Regular, sans-serif;
    font-weight: 400;
}

.rightHeader {
    gap: 24px;
}

.rightHeader > * {
    cursor: pointer;
}

.exit {
    position: relative;
    top: 1px;
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 70px;
}

.exit path {
    fill: var(--color-gray-3);
}



@media screen and (max-width: 480px) {
    .header {
        padding: 8px 5px;
    }

    .rightHeader {
        padding-right: 0;
    }

    .exit {
        margin-left: 0;
    }
}
