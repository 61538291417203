
.wrapper {
    flex: 1;
    position: relative;
    background-color: var(--color-gray-3);
}

.container {
    display: flex;
    gap: 16px;
    padding: 0 0 0 30px;
}

.rowFiltr {
    background-color: white;
    max-width: 284px;
    max-height: 820px;
    width: 100%;
    height: 100%;
    padding: 8px, 0px, 94px, 0px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px;
    
}


/* content */


.rowContent {
    background-color: white;
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;
    border: 1px;
    
}

.contentWrapper {
    margin: 60px 0;
    max-width: 850px;
}

.textWrapper {
    margin: 20px 0;
}


.figureEmbed {
    width: 100%; /* Ширина видео */
    max-width: 600px; /* Максимальная ширина */
    height: auto; /* Высота автоматически подстраивается */
    margin: 20px auto; /* Отцентровать видео */
}

.figureEmbed img,
.figureEmbed iframe {
    width: 100%; /* Ширина видео или изображения */
    height: auto; /* Высота автоматически подстраивается */
}