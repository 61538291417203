.ant-checkbox-wrapper {
    margin-inline-start: 0 !important;
}
.ant-checkbox-wrapper > span:last-child {
    padding-inline-end: 0 !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: var(--color-brand-2) !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-tree-select-dropdown .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: var(--color-brand-2) !important;
}



.ant-input, .ant-input-password, .settings .ant-select-selector {
    background-color: var(--color-gray-2) !important;
}
.ant-input:hover, .ant-input-password:hover {
    border-color: var(--color-brand-3) !important;
}
.ant-input:focus, .ant-input-password:focus {
    border-color: var(--color-brand-2) !important;
    box-shadow: none !important;
}



.ant-tree-select:hover.ant-select-selector {
    border-color: #d9d9d9 !important;
}
.ant-tree-select:focus .ant-select-selector, .ant-select-focused .ant-select-selector,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: var(--color-brand-2) !important;
    box-shadow: none !important;
}
.ant-select-item-group {
    display: none !important;
}
.ant-select-selection-item-content {
    max-width: 185px !important;
}
.ant-select-arrow {
    color: var(--color-gray-9) !important;
}



.ant-collapse-header {
    align-items: center !important;
    border-radius: unset !important;
    padding: 2px 20px !important;
}
.ant-drawer-header {
    padding-bottom: 0 !important;
    border-bottom: none !important;
}
.ant-drawer-body .ant-collapse-header {
    border-bottom: 1px solid var(--color-gray-5) !important;
}
.ant-drawer-body .cities-alphabet .ant-collapse-header {
    border-bottom: none !important;
}
.ant-collapse-content-box {
    padding: 0 !important;
}
.ant-collapse-item {
    border-radius: unset !important;
    transition: padding-bottom .3s ease-in-out;
}
.cities-district .ant-collapse-item-active .ant-collapse-item-active {
    border-bottom: 1px solid var(--color-gray-5) !important;
    padding-bottom: 10px !important;
}
.cities-alphabet .ant-collapse-item-active {
    padding-bottom: 10px !important;
}
.report .ant-collapse-expand-icon {
    display: none !important;
}


.ant-dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}
.ant-dropdown-menu::-webkit-scrollbar {
    background-color: var(--color-white);
    width: 12px;
    border-radius: 0 8px 8px 0;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 8px;
}



.ant-drawer-title .ant-input-affix-wrapper {
    background-color: var(--color-gray-2) !important;
}
.ant-drawer-body::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 6px;
    border: 1px solid transparent;
}
.ant-drawer-body::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-light);
    border-radius: 8px;
}
.ant-drawer-mask {
    background: none !important;
}
.ant-drawer .ant-drawer-title {
    font-weight: 400;
}



.ant-btn-primary:not(:disabled) {
    background-color: var(--color-brand-2) !important;
}
.ant-btn-default:not(:disabled):hover {
    color: var(--color-brand-2) !important;
    border-color: var(--color-brand-2) !important;
}



.ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--color-brand-2) !important;
    border-color: var(--color-brand-2) !important;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner, .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: var(--color-brand-2) !important;
}


.ant-list-item {
    padding: 0 !important;
}


.rc-virtual-list-scrollbar-thumb, .ant-select-tree-list-scrollbar-thumb {
    left: 4px !important;
    width: 5px !important;
    background-color: var(--color-gray-light) !important;
}
.ant-select-multiple .ant-select-selection-item {
    background-color: rgba(92, 181, 221, 0.15);
}

.ant-modal-content {
    padding: 32px 24px !important;
}
.ant-tooltip {
    line-height: 14px !important;
}
.ant-tooltip-inner {
    background-color: var(--color-gray-7) !important;
}

.ant-tooltip-arrow {
    --antd-arrow-background-color: var(--color-gray-7) !important;
}



@media screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 90% !important;
    }
}



@media screen and (max-width: 480px) {
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .ant-drawer-header {
        padding: 16px !important;
    }
}
