* {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
}

html, body, #root, .App {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    overflow-x: hidden;
}

.App {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}
