.spinner {
    display: block;
    margin: 0 auto;
}

.spinner path {
    fill: var(--color-blue);
}

.xs, .xs svg {
    width: 15px;
    height: 15px;
}

.s, .s svg {
    width: 25px;
    height: 25px;
}

.m, .m svg {
    width: 35px;
    height: 35px;
}

.l, .l svg {
    width: 50px;
    height: 50px;
}
