.wrapper {
    padding: 12px 16px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-5);
    border-radius: 4px;
}

.title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dragIcon {
    position: relative;
    top: -1px;
    cursor: move;
}

.titleText {
    font-weight: 500;
}

.rightTitleWrapper {
    position: absolute;
    top: -3px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    min-height: 28px;
}

.allSet {
    margin-left: auto;
}

.iconCircle {
    position: relative;
    top: 1px;
}

.iconCircle:hover path {
    fill: var(--color-brand-2);
}

.button {
    margin-top: 8px;
}



.selectedItem {
    background-color: rgba(92, 181, 221, 0.15); /* Фон, похожий на "Июль 2021" */
    color: rgba(0, 0, 0, 0.88); /* Цвет текста */
    border: 1px solid #c6dbe5; /* Граница элемента */
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 5px; /* Отступ справа */
    position: relative;
  }
  
  /* Стили для крестика удаления элемента */
  .removeItem {
    color: #b1afaf; /* Цвет крестика */
    margin-left: 8px; /* Отступ от текста */
    cursor: pointer;
    font-size: 17px;
  }
  
  /* Стили для плейсхолдера */
  .placeholder {
    color: rgba(0, 0, 0, 0.25);
    margin-left: 8px;
    font-size: 15px;
    opacity: 0.5;
  }
  
  /* Стили для контейнера выбранных элементов */
  .selectedItemsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Отступ между элементами */
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    /* min-width: keys.length ? 'auto' : '100%'; Минимальная ширина */
  }

/* Дополнительные стили для TreeSelect, если нужно */
.ant-select-multiple .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 11px; /* Отступы внутри селектора */
    min-height: 38px; 
    border-radius: 6px;
    width: auto; /* Измените ширину по вашему усмотрению */
}

/* Стили для TreeSelect */
.css-dev-only-do-not-override-zjzpde .ant-select-multiple .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
    border-radius: 6px;
    width: auto; /* Измените ширину по вашему усмотрению */
}

/* Стили для placeholder внутри селектора */
.ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.25); /* Цвет текста плейсхолдера */
    font-size: 16px;
}

/* Убрать нижний отступ у последнего тега, если он один на строке */
.ant-select-multiple .ant-select-selection-item:last-child {
    margin-bottom: 0;
}

/* Убрать нижний отступ, если выбран только один тег */
.ant-select-multiple .ant-select-selection-item:not(:first-child) {
    margin-bottom: 5px; /* Добавляется отступ между тегами */
}

