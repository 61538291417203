.spinner {
    text-align: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.block {
    display: flex;
    gap: 42px;
}

.block:last-child {
    margin-top: 24px;
}

.block > .column:first-child {
    width: 220px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.innerColumn {
    gap: 8px;
}

.column input {
    max-width: 80px !important;
}

.data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.ml {
    display: flex;
    flex-direction: column;
    
    gap: 8px;
    margin-left: 24px;
}

.separator {
    border-bottom: 1px solid var(--color-gray-light);
}


.dataRow {
    display: flex;
    justify-content: space-between; /* Выравнивание элементов по краям */
    align-items: center; /* Центрирование элементов по вертикали */
    width: 100%; /* Ширина строки равна ширине контейнера */
}

/* Стили для текста */
.text {
    margin-right: auto; /* Автоматический отступ справа, чтобы текст оставался слева */
}

/* Стили для поля ввода */
.input {
    width: auto; /* Ширина поля ввода по содержимому */
    min-width: 80px; /* Минимальная ширина, чтобы поля ввода не были слишком узкими */
}

@media screen and (max-width: 768px) {
    .block {
        flex-direction: column;
    }
}

