:root {
    --color-primary: #171717;
    --color-blue: #35477D;
    --color-gray-medium: #949494;
    --color-gray-light: #e8e8e8;
    --color-white: #fff;
    --color-red: #ff4d4f;
    --color-gray-10: #181A1B;
    --color-gray-9: #373C3F;
    --color-gray-8: #4E575E;
    --color-gray-7: #7E8B93;
    --color-gray-5: #DBE4E8;
    --color-gray-3: #F3F6F9;
    --color-gray-2: #F7FAFC;
    --color-brand-3: #76C8ED;
    --color-brand-2: #5CB5DD;
    --color-brand-1: #35477D;
}