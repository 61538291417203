.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.list {
    margin: 0 20px;
    width: 600px;
    max-width: 600px;
    overflow: hidden;
}

.link {
    padding: 16px 24px;
    width: 100%;
    background-color: var(--color-white);
}

.link:hover {
    background-color: var(--color-gray-3);
}
