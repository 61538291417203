.wrapper {
    display: flex;
    width: 700px;
    flex-direction: column;
    gap: 24px;
}

.block {
    display: flex;
    gap: 56px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.separator {
    border-bottom: 1px solid var(--color-gray-light);
}

.title {
    margin-bottom: 10px;
}

