.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
    margin: 58px auto;
    width: 1200px;
}

.title {
    font-family: Neue-Haas-Unica-Medium, sans-serif;
    max-width: 650px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -50px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 295px;
}

.field {
    display: flex;
    flex-direction: column-reverse;
    gap: 2px;
}

.input:focus + span {
    color: var(--color-brand-2) !important;
}

.input:has(input:focus) + span {
    color: var(--color-brand-2) !important;
}

.image {
    width: 600px;
    max-width: 100%;
}

.logo {
    position: absolute;
    top: 0;
    right: 20px;
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.button {
    margin-top: 24px;
}



@media screen and (max-width: 1200px) {
    .wrapper {
        max-width: 100%;
    }

    .bottom {
        flex-wrap: wrap;
    }

    .bottom a {
        padding-right: 10px;
    }
}



@media screen and (max-width: 950px) {
    .content {
        position: static;
        margin-top: 40px;
    }
}



@media screen and (max-width: 480px) {
    .wrapper {
        margin-top: 20px;
    }

    .content {
        justify-content: center;
        margin: 24px 0
    }

    .form {
        width: 100%;
    }
}
