.wrapper, .block, .inlineBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.allDeleteWrapper {
    display: flex;
    gap: 24px;
    align-items: center;
}

.inlineBlock {
    gap: 4px;
}

.blockLetter {
    gap: 0;
}

.districtsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.districtsTitle svg {
    cursor: pointer;
}

.line, .checkbox {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 8px;
    align-items: center;
}

.checkbox {
    gap: 8px;
}

.region {
    line-height: 150%;
}

.cities {
    margin-left: 45px;
}

.separator {
    border-bottom: 1px solid var(--color-gray-light);
}


.line {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 8px;
    align-items: center;
    margin-left: 25px;
}

.myElement::before {
    content: ''; /* Необходимо для создания псевдоэлемента */
    position: absolute; /* Позволяет позиционировать псевдоэлемент внутри .myElement */
    top: 0; /* Расположение псевдоэлемента */
    width: 100%; /* Ширина фона */
    height: 27px; /* Высота фона */
    background-color: var(--color-gray-2); /* Цвет фона */
}

.myElement {
    position: relative; /* Необходимо для корректного позиционирования псевдоэлемента */
    /* Другие стили для .myElement */
}
.otstup{
    margin-left: 25px;
}