.wrapper {
    flex: 1;
    position: relative;
    background-color: var(--color-gray-3);
}

.content {
    display: flex;
    gap: 16px;
    padding: 12px 30px 0;
}

.reportWindowWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
}

.pageMenu {
    display: flex;
    align-items: center;
    gap: 24px;
}

.pageMenu > *:first-child {
    margin-left: auto;
}

.pageMenu > *:last-child {
    margin-left: 5px;
}

.menuElem {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.menuElem:hover .linkText {
    color: var(--color-brand-2) !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menuElem:hover path {
    fill: var(--color-brand-2);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.settingsButton > div > div {
    display: flex;
    align-items: center;
}

.settingsButtonSmall {
    padding: 4px 8px !important;
}

.openMenuElemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 5px 0;
}

.downloadMenuElem {
    padding: 5px 0;
}

.separator {
    width: calc(100% + 48px);
    transform: translateX(-24px);
}

.input {
    margin: 8px 0;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    padding-top: 8px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.titleWrapperText {
    display: flex;
    gap: 16px;
    align-items: center;
    align-self: flex-start;
    border-bottom: 1px solid var(--color-gray-light);
}

span.title {
    padding-bottom: 12px;
    border-bottom: 2px solid transparent;
}

span.title:hover {
    cursor: pointer;
    color: var(--color-brand-2) !important;
}

span.activeTitle {
    color: var(--color-brand-2) !important;
    border-bottom: 2px solid var(--color-brand-2);
}

.settings {
    position: absolute;
    bottom: 0;
    left: 367px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 48px;
    align-items: center;
    width: calc(100% - 367px);
    padding: 5px 20px;
    background-color: var(--color-gray-5);
    transition: all .3s ease-in-out;
}

.fullSettings {
    left: 0;
    width: 100%;
}

.drawerWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.drawerWrapper > *:first-child {
    margin-bottom: 16px;
}

.drawerButtons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: auto;
}



@media screen and (max-width: 480px) {
    .content {
        padding: 12px 5px;
    }

    .pageMenu {
        padding-left: 30px;
    }

    .menuElem {
        justify-content: center;
    }

    .settings {
        width: 100%;
        left: unset;
        column-gap: 24px;
        padding: 5px 10px;
    }

    .drawerButtons {
        padding-bottom: 16px;
    }
}
