.sidebar {
    width: 284px; /* Fixed width */
    background-color: white;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Space between items */
    position: fixed; /* Элемент остается фиксированным при прокрутке страницы */
    top: 70px; /* Отступ сверху */
    left: 20px; /* Отступ слева */
    overflow-y: auto; /* Добавляет вертикальную прокрутку, если содержимое выходит за рамки высоты */
  }
  
  .sidebarItem {
    height: 40px; /* Fixed height */
    display: flex;
    align-items: center; /* Center items vertically */
    padding: 0 16px; /* Vertical padding is 0, horizontal padding is 16px */

    cursor: pointer;
    border-right: 3px solid transparent; /* Invisible border for active state */
  }
  
  .sidebarItem:hover {
    background-color: rgba(92, 181, 221, 0.05); /* Light blue background on hover */
    color: #5CB5DD; /* Light blue text on hover */
  }
  
  .sidebarItem.active {
    color: #5CB5DD; /* Light blue text for active item */
    border-right: 3px solid #5CB5DD; /* Light blue border for active item */
    background-color: rgba(92, 181, 221, 0.05); /* Light blue background on hover */

  }


  
  .subLinks {
    display: none; /* Initially don't display sub-links */
    gap: 10px; /* Space between items */
}

.sidebarSubItem {
    /* Other styles for sub-links */
    height: 40px; /* Fixed height */
    display: flex;
    align-items: center; /* Center items vertically */
    padding: 0 46px; /* Vertical padding is 0, horizontal padding is 16px */

    cursor: pointer;
    border-right: 3px solid transparent; /* Invisible border for active state */
}

.sidebarSubItem:hover {
  background-color: rgba(92, 181, 221, 0.05); /* Light blue background on hover */
  color: #5CB5DD; /* Light blue text on hover */
}


/* Add styles for when sub-links are visible */
.sidebarItem.active + .subLinks {
    display: block;
}


.sidebarItem .arrow {
    transform: rotate(0); /* Стрелка указывает вправо */
    cursor: pointer;
}

.sidebarItem .arrow.open {
    transform: rotate(90deg); /* Стрелка указывает вниз */
}

.subLinksContainer.hidden {
    display: none; /* Скрытие подссылок */
}

/* Уже существующие стили... */

.subLinksContainer {
  padding-left: 20px; /* Индентация для подзаголовков */
  display: none; /* По умолчанию подзаголовки скрыты */
}

.sidebarItem.active .subLinksContainer {
  display: block; /* Показываем подзаголовки для активного заголовка */
}

.headingLink {
  display: block;
  margin-left: 20px; /* Отступ для подзаголовков */
  color: #666; /* Серый цвет текста для подзаголовков */
}

/* Примените стили для подзаголовков */
.headingLink.level-h3 {
  margin-left: 40px; /* Больший отступ для подзаголовков */
  color: #999; /* Более светлый серый цвет текста */
}


.subheading {
  padding-left: 20px; /* Добавить отступ для подзаголовков */
  color: #888; /* Сделать текст подзаголовков более светлым */
}

